import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from '../Footer';

const BlogHome = () => {
    const navigate = useNavigate();
  // Dummy data for blog posts (you can replace this with dynamic data from an API or state)
  const blogPosts = [
    {
      title: '10 Best Free AI Study Tools for Students',
      author: 'Jignesh Kumar',
      date: 'September 22, 2024',
      image: '/blogs/1.jpg', // Update with the correct image path
      slug: '10-Best-Free-AI-Study-Tools-for-Students',
    },
    {
      title: 'Top 10 Math AI Tools',
      author: 'Jignesh Kumar',
      date: 'September 23, 2024',
      image: '/blogs/2.jpg', // Update with the correct image path
      slug: 'Top-10-Math-AI-Tools',
    },
    {
      title: 'Best AI for Essay Writing in 2024',
      author: 'Jignesh Kumar',
      date: 'September 30, 2024',
      image: '/blogs/5.jpg', // Update with the correct image path
      slug: 'Best-AI-for-Essay-Writing-in-2024',
    }
    // Add more blog posts here...
  ];

  return (
    <>
    <div className="bg-gray-100 py-12">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Blog Articles
        </h1>

        {/* Blog List */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((blog, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer" 
            onClick={() => navigate(`/blog/${blog.slug}`)}>
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2 text-gray-800">
                  {blog.title}
                </h3>
                <p className="text-gray-600 mb-4">By {blog.author} | {blog.date}</p>
                <Link
                  to={`/blog/${blog.slug}`}
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default BlogHome;