import React, { useEffect, useState } from "react";
import { getDatabase, ref, get, child, remove } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { format } from "date-fns";
import { FaTrash, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BuyCreditsButton from './BuyCreditsButton'; 
import NoDataPlaceholder from "./NoDataPlaceholder"; 
import { toast } from "react-toastify";

const DocumentsQueue = () => {
  const [mocktests, setMocktests] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [user] = useAuthState(auth);
  const [docProgress, setDocProgress] = useState({}); // State for quiz progress
  const database = getDatabase();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchMocktests();
      fetchPodcasts(); // Fetch podcasts as well
    }

    // Initialize WebSocket connection for quiz progress updates
    const ws = new WebSocket('wss://app.studentaitools.com/socket'); // Replace with your WebSocket URL

    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data); // Attempt to parse the data as JSON
        console.log(data);

        if(data.success==false) {
          toast.error(data.message);  
        }else{
          if (data.quizId && data.progress) {
            setDocProgress((prev) => ({
              ...prev,
              [data.quizId]: data.progress,
            }));
          }
          if (data.podcastId && data.progress) {
            setDocProgress((prev) => ({
              ...prev,
              [data.podcastId]: data.progress,
            }));
          }
        }
      } catch (error) {
        console.log('Received a non-JSON message:', event.data); // Handle non-JSON messages (plain text)
        // Optionally, you can display or log the plain text message here if needed
      }
    };    

    return () => ws.close(); // Clean up on component unmount

  }, [user]);

  // Fetch only 'mocktest' type documents with 'processing' state
  const fetchMocktests = async () => {
    const dbRef = ref(database);
    const mocktestsSnap = await get(child(dbRef, `mocktests/${user.uid}`));

    const mocktestList = [];
    if (mocktestsSnap.exists()) {
      const data = mocktestsSnap.val();
      Object.keys(data).forEach((key) => {
        if (data[key].state === "processing") {
          mocktestList.push({ id: key, ...data[key], type: "mocktest" });
        }
      });
    }

    mocktestList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setMocktests(mocktestList);
    setLoading(false);
  };

  const fetchPodcasts = async () => {
    const dbRef = ref(database);
    const podcastsSnap = await get(child(dbRef, `podcasts/${user.uid}`));

    const podcastList = [];
    if (podcastsSnap.exists()) {
      const data = podcastsSnap.val();
      Object.keys(data).forEach((key) => {
        if (data[key].state === "processing") {
          podcastList.push({ id: key, ...data[key], type: "podcast" });
        }
      });
    }

    setPodcasts(podcastList);
    setLoading(false);
  };

const handleView = async (docId, type) => {
  try {
    if(type=='mocktest'){
      const database = getDatabase();
      let quizId = docId;
      const quizRef = ref(database, `mocktests/${user.uid}/${quizId}`);
      // Fetch the quiz data from Firebase using the quizId
      const snapshot = await get(quizRef);
      if (snapshot.exists()) {
        const quizData = snapshot.val().questions || []; // Access the questions array in the quiz data

        // Navigate to the quiz page with the fetched questions
        navigate("/quiz", { state: { quizData: quizData, quizId: quizId } });
      } else {
        console.error("No quiz found with the provided quizId.");
      }
    }else{
      let podcastId = docId;
      const pocastRef = ref(database, `podcasts/${user.uid}/${podcastId}`);
      // Fetch the pocast data from Firebase using the podcastId
      const snapshot = await get(pocastRef);
      if (snapshot.exists()) {
        const script = snapshot.val().script; // Access the questions array in the pocast data
        const filePath = snapshot.val().filePath;
        // Navigate to the pocast page with the fetched questions
        navigate("/learn-with-podcast/"+podcastId, { state: { podcast: JSON.stringify({script: script, filePath: filePath}) } });
      }
    }
  } catch (error) {
    console.error("Error fetching quiz data:", error);
  }
};


  // Handle deletion, including sending a delete job request to the backend
  const deleteSummary = async (summaryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (user) {
          // REST API to delete the quiz job from the Redis queue
          fetch(`https://app.studentaitools.com/api/delete-job/${summaryId}`, { method: 'DELETE' })
            .then(() => {
              const summaryRef = ref(database, `mocktests/${user.uid}/${summaryId}`);
              remove(summaryRef).then(() => {
                Swal.fire("Deleted!", "Your quiz Job has been deleted.", "success");
                fetchMocktests(); // Refresh the list after deletion
              });
            })
            .catch((err) => {
              console.error(err);
              Swal.fire("Error!", "Could not delete the quiz.", "error");
            });
        }
      }
    });
  };

  const combinedDocuments = [...mocktests, ...podcasts].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  //console.log(combinedDocuments)

  return (
    <div className="min-h-screen flex flex-col items-center justify-start bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-5xl">
        <h2 className="text-2xl font-bold text-center mb-6">Processing Documents</h2>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
          </div>
        ) : combinedDocuments.length === 0 ? (
          <NoDataPlaceholder /> // Show placeholder when no data
        ) : (
          <div className="overflow-x-auto w-full">
            <table className="min-w-full bg-white rounded-lg overflow-hidden">
              <thead className="bg-gray-200 text-gray-600 uppercase text-sm">
                <tr>
                  <th className="py-3 px-4 text-left">Name</th>
                  <th className="py-3 px-4 text-left">Tool</th>
                  <th className="py-3 px-4 text-left">Date</th>
                  <th className="py-3 px-4 text-left">Progress</th>
                  <th className="py-3 px-4 text-left">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {combinedDocuments.map((doc) => (
                  <tr key={doc.id} className="border-b border-gray-200">
                    <td className="py-3 px-4 break-words">{doc.type=='mocktest'?doc.originalText.split(" ").slice(0, 20).join(" "): doc.topic.split(" ").slice(0, 20).join(" ")}</td>
                    <td className="py-3 px-4">{doc.type=='mocktest'?'Mock Test Generator':'Learn With Podcast'}</td>
                    <td className="py-3 px-4">{format(new Date(doc.createdAt), "PPpp")}</td>
                    <td className="py-3 px-4">
                      {/* Progress Bar */}
                      <div className="relative w-full h-4 bg-gray-200 rounded-full">
                        <div
                          className="absolute top-0 left-0 h-4 bg-green-500 rounded-full"
                          style={{ width: `${docProgress[doc.id] || 0}%` }}
                        ></div>
                      </div>
                      <div className="text-sm text-gray-600 mt-1">{docProgress[doc.id] ? `${docProgress[doc.id]}%` : "0%"}</div>
                    </td>
                    <td className="py-3 px-4 flex space-x-4">
                      {docProgress[doc.id]=='100' && <button onClick={() => handleView(doc.id, doc.type)} className="text-blue-500 hover:text-blue-700">
                        <FaEye />
                      </button>}
                      <button
                        onClick={() => deleteSummary(doc.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <BuyCreditsButton />
    </div>
  );
};

export default DocumentsQueue;