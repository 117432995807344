import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'; // Sweetalert popup
import { v4 as uuidv4 } from 'uuid'; // Generate random password

const SubscribeSchool = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1); // Form step
  const [schoolInfo, setSchoolInfo] = useState({}); // School information
  const [repInfo, setRepInfo] = useState({}); // School representative information
  const [numStudents, setNumStudents] = useState(10); // Number of students
  const [totalCost, setTotalCost] = useState(100); // Total subscription cost

  // Handle form data changes
  const handleInputChange = (e, setState) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Go to next step
  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };

  // Go to previous step
  const handlePreviousStep = () => {
    setStep((prev) => prev - 1);
  };

  // Handle PayPal payment and subscription
  const handleSubscriptionSuccess = () => {
    const randomPassword = uuidv4().slice(0, 8); // Generate random password for school login

    // Data to send to the API
    const schoolData = {
      ...schoolInfo,
      ...repInfo,
      numStudents,
      totalCost,
      subscriptionDate: new Date().toISOString(),
      loginDetails: {
        email: repInfo.repEmail,
        password: randomPassword
      }
    };

    // Call register-school API (simulate API call here)
    fetch('/register-school', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(schoolData)
    })
      .then((response) => response.json())
      .then(() => {
        Swal.fire({
          title: "Subscription Successful!",
          text: "You can add your students at https://school.studentaitools.com/login. Check your email for more details.",
          icon: "success"
        });
        onClose(); // Close the modal after success
      })
      .catch((error) => {
        console.error("Error registering school:", error);
        toast.error("Failed to register school.");
      });
  };

  // Close Modal
  const handleClose = () => {
    onClose();
    setStep(1); // Reset to step 1 when closed
  };

  return (
    <>
      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-8 w-3/4 max-w-3xl relative text-gray-800">
            {/* Close Button (X) */}
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              onClick={handleClose}
            >
              &#x2715; {/* Unicode for X symbol */}
            </button>

            {step === 1 && (
              <div>
                <h3 className="text-2xl font-bold mb-4">Step 1: School Information</h3>
                <input
                  type="text"
                  name="schoolName"
                  placeholder="School Name"
                  onChange={(e) => handleInputChange(e, setSchoolInfo)}
                  className="w-full mb-4 px-4 py-2 border rounded"
                />
                <input
                  type="text"
                  name="schoolAddress"
                  placeholder="School Address"
                  onChange={(e) => handleInputChange(e, setSchoolInfo)}
                  className="w-full mb-4 px-4 py-2 border rounded"
                />
                <input
                  type="text"
                  name="schoolCity"
                  placeholder="City"
                  onChange={(e) => handleInputChange(e, setSchoolInfo)}
                  className="w-full mb-4 px-4 py-2 border rounded"
                />
                <input
                  type="text"
                  name="schoolCountry"
                  placeholder="Country"
                  onChange={(e) => handleInputChange(e, setSchoolInfo)}
                  className="w-full mb-4 px-4 py-2 border rounded"
                />
                <button
                  onClick={handleNextStep}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  Next
                </button>
              </div>
            )}

            {step === 2 && (
              <div>
                <h3 className="text-2xl font-bold mb-4">Step 2: School Representative Information</h3>
                <input
                  type="text"
                  name="repName"
                  placeholder="Representative Name"
                  onChange={(e) => handleInputChange(e, setRepInfo)}
                  className="w-full mb-4 px-4 py-2 border rounded"
                />
                <input
                  type="email"
                  name="repEmail"
                  placeholder="Representative Email"
                  onChange={(e) => handleInputChange(e, setRepInfo)}
                  className="w-full mb-4 px-4 py-2 border rounded"
                />
                <button
                  onClick={handlePreviousStep}
                  className="bg-gray-400 text-white px-4 py-2 rounded-lg mr-4 hover:bg-gray-500 transition duration-300"
                >
                  Back
                </button>
                <button
                  onClick={handleNextStep}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  Next
                </button>
              </div>
            )}

            {step === 3 && (
              <div>
                <h3 className="text-2xl font-bold mb-4">Step 3: Select Number of Students</h3>
                <input
                  type="range"
                  min="10"
                  max="1000"
                  value={numStudents}
                  onChange={(e) => {
                    setNumStudents(Number(e.target.value));
                    setTotalCost(Number(e.target.value) * 10);
                  }}
                  className="w-full h-2 bg-blue-300 rounded-lg appearance-none cursor-pointer mb-4"
                />
                <div className="flex justify-between text-gray-700 mb-4">
                  <span>10 students</span>
                  <span>{numStudents} students</span>
                  <span>1000 students</span>
                </div>
                <p className="text-lg font-bold mb-4">Total Cost: ${totalCost}</p>

                <PayPalButton
                  amount={totalCost} // Subscription cost
                  onSuccess={handleSubscriptionSuccess}
                  options={{
                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    currency: "USD",
                  }}
                />

                <button
                  onClick={handlePreviousStep}
                  className="bg-gray-400 text-white px-4 py-2 rounded-lg mr-4 hover:bg-gray-500 transition duration-300"
                >
                  Back
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default SubscribeSchool;