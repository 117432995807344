import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'; // Import icons from react-icons

const FAQComponent = ({ faqData }) => {
  const [activeIndex, setActiveIndex] = useState(0); // Set initial index to 0 to open the first accordion

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
        Frequently Asked Questions
      </h2>
      {faqData.map((faq, index) => (
        <div key={index} className="mb-4">
          <button
            className="w-full flex justify-between items-center text-left px-4 py-3 bg-blue-600 text-white font-semibold rounded-lg focus:outline-none hover:bg-blue-700 transition duration-300"
            onClick={() => toggleAccordion(index)}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
            </span>
          </button>
          {activeIndex === index && (
            <div className="px-4 py-3 bg-white">
              <p className="text-gray-800">{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQComponent;