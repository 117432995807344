import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getDatabase, ref, get, set } from "firebase/database"; // Firebase Realtime Database
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

const LoginModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate(); // To navigate after login
  const db = getDatabase(); // Initialize Realtime Database

  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Logged in successfully!", userCredential);
        navigate("/dashboard"); // Redirect to dashboard after login
        onClose(); // Close modal
      })
      .catch((error) => {
        setError("Invalid email or password. Please try again.");
        console.error("Error logging in: ", error);
      });
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        const userRef = ref(db, `users/${user.uid}`);

        // Check if user exists in database
        get(userRef).then((snapshot) => {
          if (!snapshot.exists()) {
            // If user doesn't exist, set the default limits for tools
            set(ref(db, `users/${user.uid}`), {
              answerLimit: 2,
              essayLimit: 2,
              mathSolverLimit: 2,
              quizLimit: 2,
              summarizeLimit: 2,
              paraphraseLimit: 2,
              homework_helper_message_limit: 50,
              worksheetLimit:2,
              planLimit: 2,
              podcastLimit: 2,
              teacher_assistant_message_limit: 50
            }).then(() => {
              console.log("New user created with default limits.");
              navigate("/dashboard"); // Redirect to dashboard after setting limits
              onClose(); // Close modal
            }).catch((error) => {
              console.error("Error setting default limits: ", error);
              setError("Error setting user limits. Please try again.");
            });
          } else {
            // If user exists, proceed to dashboard
            navigate("/dashboard");
            onClose(); // Close modal
          }
        }).catch((error) => {
          console.error("Error checking user in database: ", error);
          setError("Error logging in with Google. Please try again.");
        });
      })
      .catch((error) => {
        setError("Google Sign-In failed. Please try again.");
        console.error("Error signing in with Google: ", error);
      });
  };

  if (loading) {
    return <div>Loading...</div>; // Optional: Add a spinner or loading state
  }

  return (
    <>
      {/* Modal Overlay */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          {/* Modal Content */}
          <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={onClose}
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold text-center mb-6">Login</h2>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}

            {/* Login Form */}
            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700">Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Enter your password"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Login
              </button>
            </form>

            {/* Divider */}
            <div className="my-4 flex items-center justify-center">
              <span className="text-gray-500">or</span>
            </div>

            {/* Google Sign-In Button */}
            <button
              onClick={googleSignIn}
              className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300"
            >
              Sign In with Google
            </button>

            <div className="mt-4 text-center">
              <a href="/signup" className="text-blue-600 hover:underline">
                Don't have an account? Sign up here.
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginModal;
