// src/components/Signup.js
import React, { useState, useEffect  } from "react";
import { auth } from "../firebase";
import { createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, set } from "firebase/database";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [user, loading, errorAuth] = useAuthState(auth); // Track authentication state
  const navigate = useNavigate(); // To navigate after signup
  
  // Redirect to dashboard if user is already logged in
  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);


  const handleSignup = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        return updateProfile(user, {
          displayName: name,
        }).then(() => {
          const db = getDatabase();
          set(ref(db, `users/${user.uid}`), {
            answerLimit: 2,
            essayLimit: 2,
            mathSolverLimit: 2,
            quizLimit: 2,
            summarizeLimit: 2,
            paraphraseLimit: 2
          }).then(() => {
            navigate("/dashboard");
          });
        });
      })
      .catch((error) => {
        setError("Error during signup. Please try again.");
        console.error("Signup error: ", error);
      });
  };

  const googleSignUp = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("Google Sign-Up successful!", result.user);
        const user = result.user;
        const db = getDatabase();
        set(ref(db, `users/${user.uid}`), {
          answerLimit: 2,
          essayLimit: 2,
          mathSolverLimit: 2,
          quizLimit: 2,
          summarizeLimit: 2,
          paraphraseLimit: 2,
          homework_helper_message_limit: 50,
          worksheetLimit:2,
          planLimit: 2,
          podcastLimit: 2,
          teacher_assistant_message_limit: 50
        }).then(() => {
          navigate("/dashboard");
        });
      })
      .catch((error) => {
        setError("Google Sign-Up failed. Please try again.");
        console.error("Error signing up with Google: ", error);
      });
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User logged out.");
      })
      .catch((error) => {
        console.error("Error logging out: ", error);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-6">
          {user ? "Welcome, " + user.displayName : "Signup"}
        </h2>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        {user ? (
          <div className="text-center">
            <button
              onClick={handleLogout}
              className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300"
            >
              Logout
            </button>
          </div>
        ) : (
          <>
            <form onSubmit={handleSignup} className="space-y-4">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700">Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Enter your password"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Signup
              </button>
            </form>

            <div className="my-4 flex items-center justify-center">
              <span className="text-gray-500">or</span>
            </div>
            <button
              onClick={googleSignUp}
              className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300"
            >
              Sign Up with Google
            </button>

            <div className="mt-4 text-center">
              <a href="/login" className="text-blue-600 hover:underline">
                Already have an account? Login here.
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Signup;
