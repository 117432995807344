import React, { useState, useEffect } from "react";
import { generateBulletPoints } from "../openaiService"; // OpenAI service for bullet point generation
import { getDatabase, ref, set, push, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom";
import BuyCreditsButton from "./BuyCreditsButton";
import { FaFileUpload, FaClipboard } from "react-icons/fa";
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import { Helmet } from 'react-helmet';
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { motion } from 'framer-motion';
import Download from "./Download";

const BulletPointAnswerGenerator = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewMode, setViewMode] = useState(false); // To hide controls in view mode
  const [answerLimit, setAnswerLimit] = useState(2); // Free limit for unsubscribed users
  const [isSubscribed, setIsSubscribed] = useState(false); // Subscription status
  const [isModalOpen, setModalOpen] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  var tool= { name: "Bullet Point Answer Generator"}

  useEffect(() => {
    if (user) {
      tool.uid= user.uid;
      const fetchUserData = async () => {
        const userRef = ref(getDatabase(), `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setIsSubscribed(userData.subscriptionStatus === "subscribed");
          if (userData.answerLimit !== undefined) {
            setAnswerLimit(userData.answerLimit);
          } else {
            await update(userRef, { answerLimit: 2 }); // Initialize limit
          }
        }
      };
      fetchUserData();
    }

    if (location.state?.answer) {
      setAnswer(location.state.answer);
      setViewMode(true);
    }else{
      setAnswer('');
      setViewMode(false);
    }
  }, [user, location]);

  const database = getDatabase();

  // Handle generating answers
  const handleGenerateAnswer = async (e) => {
    e.preventDefault();
    if (!user) {
      setModalOpen(true);
      return;
    }
    setError("");
    setAnswer("");
    setLoading(true);

    if (!question.trim()) {
      setError("Please enter a question.");
      setLoading(false);
      return;
    }

    if (answerLimit <= 0) {
      toast.error(
        <span>
          Buy credits for generating more bullet point answers.
        </span>
      );
      setLoading(false);
      return;
    }

    try {
      const result = await generateBulletPoints(question);
      setAnswer(result);

      // Save the result to Firebase under the logged-in user's uid
      if (user) {
        const answerRef = push(ref(database, `summaries/${user.uid}`));
        await set(answerRef, {
          originalText: question,
          summary: result,
          createdAt: new Date().toISOString(),
          tool: "Bullet Point Answer Generator"
        });

        toast.success("Saved to Documents!");

        // Deduct from the free limit if not subscribed
        if (!isSubscribed) {
          const newLimit = answerLimit - 1;
          setAnswerLimit(newLimit);
          const userRef = ref(database, `users/${user.uid}`);
          await update(userRef, { answerLimit: newLimit });
        }
      }
    } catch (error) {
      setError("There was an issue generating the answer. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleDownload = () => {
    let answer1= document.getElementById('answer').textContent;
    if (!answer1) {
      toast.error("No content to download!");  // Show error if there's no answer generated
      return;
    }
  
    const blob = new Blob([answer1], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.href = url;
    link.download = `answer.txt`;  // Filename based on the topic
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleCopy = () => { 
    let answer1 = document.getElementById('answer').innerHTML;
    if (answer1) {
      const blob = new Blob([answer1], { type: 'text/html' });
      const clipboardItem = new ClipboardItem({ 'text/html': blob });
  
      navigator.clipboard.write([clipboardItem])
        .then(() => {
          toast.success("Text copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
          toast.error("Failed to copy text.");
        });
    } else {
      toast.error("No text to copy!");
    }
  };  
  

  return (
    <>
    <Helmet>
        <title>Bullet Point Answer Generator - Quick Answers for Your Questions | StudentAITools</title>
        <meta
          name="description"
          content="Generate concise and effective bullet point answers to any question with our Bullet Point Answer Generator tool. Ideal for students and professionals."
        />
      </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-5xl flex justify-between items-center mb-4">
          {viewMode && (
            <button
              onClick={() => navigate("/documents")}
              className="text-blue-600 hover:underline"
            >
              &larr; Back to Documents
            </button>
          )}
          
          {user && (
          <div className="text-right">
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Credit Left: {answerLimit}
            </span>
          </div>
          )}
      </div>
  
  
      <h1 className="text-3xl font-bold text-center mb-4">Bullet Point Answer Generator</h1>
      <p className="text-lg text-gray-600 text-center mb-8">
        Quickly generate clear and concise bullet point answers from complex questions
      </p>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        <motion.div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full flex flex-col lg:flex-row"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        viewport={{ once: true, amount: 0.1 }}>

          {!viewMode && (
            <div className="w-full lg:w-1/2 pr-4 lg:border-r mb-4 lg:mb-0">
              <h2 className="text-lg font-bold mb-4">Enter Question</h2>
              <form onSubmit={handleGenerateAnswer} className="space-y-4">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700">Enter your question</label>
                  <textarea
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    rows="11"
                    placeholder="Type your question here..."
                  ></textarea>
                </div>

                <button type="submit" className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300 flex justify-center items-center" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Generating...</span>
                    </div>
                  ) : "Generate Answer"}
                </button>
              </form>
            </div>
          )}
          
          <div className={viewMode ? "w-full pl-4" : "w-full lg:w-1/2 pl-4"}>
            <h2 className="text-lg font-bold mb-4">Answer</h2>
    
            {/* Paraphrased text output */}
            <div className="bg-gray-100 p-4 rounded-lg h-72 lg:h-80 relative overflow-auto">
              {/* Paraphrased text */}
              <div className="text-gray-700 bullet_answer" id="answer" dangerouslySetInnerHTML={{ __html: answer  || "Your answer will appear here..." }}>
              </div>
            </div>
            {/* Buttons below the result */}
            <div className="flex justify-between mt-4">
              {/* Download Button */}
              <Download doc={{ output_id: "answer"}} />
              {/* <button
                onClick={handleDownload}
                className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
              >
                Download
              </button> */}

              {/* Copy to Clipboard Button */}
              <button
                onClick={handleCopy}
                className="text-gray-500 hover:text-gray-700"
                title="Copy to Clipboard"
              >
                <FaClipboard className="text-xl text-gray-600 hover:text-blue-600" />
              </button>
            </div>

          </div>
        </motion.div>
        
      <Feedback tool={{"name":"Bullet Point Answer Generator"}} />
    

        {!viewMode && ( 
          <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.1 }}>
          <h2 className="text-2xl font-bold mb-4">What is a Bullet Point Answer Generator?</h2>
          <p className="text-gray-700 mb-4">
            The Bullet Point Answer Generator is an AI-powered tool designed to help you generate concise, structured answers in bullet-point format. Whether you're summarizing long texts, preparing for presentations, or drafting responses for exams, our tool helps streamline the process, ensuring clarity and organization.
          </p>
        
          <h3 className="text-xl font-semibold mb-2">Why Use a Bullet Point Answer Generator?</h3>
          <p className="text-gray-700 mb-4">
            Bullet point answers help improve clarity, making complex information more digestible. With our AI-based tool, you can efficiently break down long-form answers into key points, perfect for presentations, study notes, or business reports. This approach ensures that essential details stand out, providing a well-structured response that is easy to understand.
          </p>
        
          <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools?</h3>
          <p className="text-gray-700 mb-4">
            StudentAITools offers an intuitive and reliable Bullet Point Answer Generator powered by advanced AI. Our tool generates precise, relevant answers by summarizing key details from your input, making it suitable for students, professionals, and content creators alike. With a focus on efficiency and accuracy, we ensure that you get high-quality bullet points that are perfect for your needs.
          </p>
        
          <h3 className="text-xl font-semibold mb-2">How to Use Our Bullet Point Answer Generator Tool</h3>
          <ul className="list-disc list-inside mb-4 text-gray-700">
            <li>Enter your question or text input.</li>
            <li>Click "Generate" to get bullet point answers.</li>
            <li>Review the output and copy it to your document or presentation.</li>
          </ul>
        
          <h3 className="text-xl font-semibold mb-2">Tips for Best Results</h3>
          <ul className="list-disc list-inside text-gray-700">
            <li>Provide clear and concise questions or text inputs for better output.</li>
            <li>Use the tool for a variety of purposes, including study guides, presentations, or exam preparation.</li>
            <li>Review the generated bullet points to ensure they capture the essential information you need.</li>
          </ul>
        
          <h3 className="text-xl font-semibold mb-2">Who Can Benefit from This Bullet Point Answer Generator Tool?</h3>
          <ul className="list-disc list-inside mb-4 text-gray-700">
            <li>Students who need quick, organized study notes or summaries.</li>
            <li>Professionals looking to create clear and concise presentations or reports.</li>
            <li>Writers and content creators who need to summarize information in an easy-to-read format.</li>
          </ul>
        
          <h3 className="text-xl font-semibold mb-2">Best Free Bullet Point Answer Generator Tools</h3>
          <ul className="list-disc list-inside mb-4 text-gray-700">
            <li><strong>Jasper AI:</strong> Known for its content generation capabilities, Jasper AI also helps break down complex topics into bullet points.</li>
            <li><strong>Writesonic:</strong> A versatile tool for generating summarized content, perfect for bullet-point formatting.</li>
            <li><strong>Copy.ai:</strong> Generates concise, actionable points for a variety of content needs.</li>
          </ul>
        
          <h3 className="text-xl font-semibold mb-2">Disclaimer</h3>
          <p className="text-gray-700">
            Our AI Bullet Point Answer Generator aims for high accuracy. However, achieving perfection is still challenging. Please note: the predictions are for demonstration purposes only. Do not rely on them for real-world applications. Use caution, as we are not liable for any damages incurred. We strongly recommend using human oversight when using our products.
          </p>
        </motion.div>        
        )}
        
        <NewsletterCard/>
      
        {user && (
        <BuyCreditsButton />
        )}
      </div>

      {!viewMode && (
        <Footer />
      )}

      <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default BulletPointAnswerGenerator;