import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set, push } from "firebase/database";
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'; // Import the hook
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ReactRecaptcha3 from 'react-google-recaptcha3';

const Newsletter = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const database = getDatabase();

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    if (!hasSeenPopup) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleSubscribe = async (e) => {
    e.preventDefault();

    try {
      // Get the reCAPTCHA token
     // Get the reCAPTCHA token
     ReactRecaptcha3.getToken().then(
        async (recaptchaToken) => {
          console.log(recaptchaToken);
          

            // Send the email and reCAPTCHA token to your backend
            const response = await axios.post('https://app.studentaitools.com/api/send-email', {
                email: email,
                subject: 'Welcome to StudentAITools',
                type: 'newsletter',
                recaptchaToken, // Send the reCAPTCHA token to the server for verification
            });
            if (response.status === 200) {
                setSubscribed(true);
                setShowPopup(false);
                toast.success(`Thank you for subscribing to our Newsletter!`);
                localStorage.setItem('hasSeenPopup', 'true');
            }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      toast.error("Failed to subscribe. Please try again.");
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem('hasSeenPopup', 'true');
  };

  if (subscribed || !showPopup) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96 relative">
        {/* Close Button */}
        <button 
          onClick={handleClosePopup} 
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="text-2xl font-semibold text-center mb-4">Subscribe to our Newsletter</h2>
        <p className="text-gray-600 text-center mb-6">Get the latest updates on our AI-powered tools for students. Stay ahead in your studies!</p>
        <form onSubmit={handleSubscribe}>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            className="border p-2 rounded w-full mb-4" 
            placeholder="Enter your email" 
            required
          />
          <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition duration-300">
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;