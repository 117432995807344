import React, { useState, useEffect, useRef  } from "react";  
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaDownload, FaChevronDown } from "react-icons/fa"; // For download and dropdown icons
import LoginModal from "./LoginModal";

const Navbar = () => {
  const [user] = useAuthState(auth);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Mobile menu toggle
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For tools dropdown
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false); // For tools dropdown
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference for the dropdown
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route

  const isSettingsPageActive = location.pathname == '/settings';
  const isReportsPageActive = location.pathname == '/reports';
  const isBlogPageActive = location.pathname == '/blog';
  const isDocumentsPageActive = (location.pathname == '/documents' || location.pathname == '/documents/');
  const isHomePageActive = location.pathname == '/';
  
  // useEffect(() => {
  //   document.documentElement.classList.remove("light", "dark");
  //   document.documentElement.classList.add(theme);
  //   localStorage.setItem("theme", theme);
  // }, [theme]);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close the dropdown
      }
      if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
        setIsDropdownOpen1(false);
      }
      if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
        setIsDropdownOpen2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef,dropdownRef1,dropdownRef2]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setIsMobileMenuOpen(false); // Close mobile menu on logout
        navigate("/"); // Redirect to login page after logout
      })
      .catch((error) => {
        console.error("Error logging out: ", error);
      });
  };
  const handleLogin = () => {
      setModalOpen(true);
  };

  const handleThemeToggle = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleDropdown1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };
  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    setIsDropdownOpen(false); // Close dropdown on mobile menu close
    setIsDropdownOpen1(false); 
    setIsDropdownOpen2(false); 
  };

  return (
    <>
    <nav className="p-4 bg-gray-900">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" onClick={closeMobileMenu}>
          <img src="/images/2.png" alt="Logo" width="250px" />
        </Link>

        {/* Hamburger Menu for Mobile */}
        <label className="swap swap-rotate lg:hidden text-white">
          {/* this hidden checkbox controls the state */}
          <input type="checkbox" onChange={toggleMobileMenu} checked={isMobileMenuOpen} />

          {/* hamburger icon */}
          <svg
            className="swap-off fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 512 512">
            <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
          </svg>

          {/* close icon */}
          <svg
            className="swap-on fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 512 512">
            <polygon
              points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
          </svg>
        </label>

        {/* Navigation links */}
        <ul
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } lg:flex flex-col lg:flex-row lg:space-x-4 text-white items-center absolute lg:static top-16 left-0 w-full lg:w-auto lg:bg-transparent transition-all duration-300 ease-in-out lg:h-auto bg-gray-900`}
          style={{zIndex:99}}
        >
          <li className={`p-2 lg:p-0 underline-animation ${isHomePageActive?'active_link':''}`}>
            <Link to="/" onClick={closeMobileMenu}>Home</Link>
          </li>
          <li className="relative p-2 lg:p-0" ref={dropdownRef1}>
            <button
              onClick={toggleDropdown1}
              className="flex items-center"
            >
             Teacher Tools <FaChevronDown className="ml-2" />
            </button>
            {isDropdownOpen1 && (
              <ul className="lg:absolute left-0 mt-2 lg:w-48 bg-gray-900 text-white shadow-lg rounded-lg z-20">
                <li className="p-2 hover:bg-gray-700">
                  <Link to="/lesson-planner" onClick={closeMobileMenu}>📚 Lesson Planner</Link>
                </li>
                <li className="p-2 hover:bg-gray-700">
                  <Link to="/worksheet-generator" onClick={closeMobileMenu}>📝Worksheet Generator</Link>
                </li>
                <li className="p-2 hover:bg-gray-700 highlight flex items-center">
                  <Link to="/mock-test-generator" onClick={closeMobileMenu}>📊 Quiz Generator</Link>
                  <span className="ml-2 animate-pulse text-yellow-400">⭐</span> {/* Glowing star */}
                </li>
                <li className="p-2 hover:bg-gray-700">
                  <Link to="/teacher-assistant" onClick={closeMobileMenu}>👩🏻‍💼 Teacher's Assistant</Link>
                </li>
              </ul>
            )}
          </li>

          {/* Tools Dropdown */}
          <li className="relative p-2 lg:p-0" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              className="flex items-center"
            >
              Student Tools <FaChevronDown className="ml-2" />
            </button>
            {isDropdownOpen && (
              <ul className="lg:absolute left-0 mt-2 lg:w-64 bg-gray-900 text-white shadow-lg rounded-lg z-20">
              <li className="p-2 hover:bg-gray-700">
                <Link to="/math-solver" onClick={closeMobileMenu}>🔢 Math Solver</Link>
              </li>
              <li className="p-2 hover:bg-gray-700">
                <Link to="/essay-writer" onClick={closeMobileMenu}>🖋️ Essay Writer</Link>
              </li>
              <li className="p-2 hover:bg-gray-700">
                <Link to="/bullet-point-generator" onClick={closeMobileMenu}>✏️ Bullet Point Answer Generator</Link>
              </li>
              <li className="p-2 hover:bg-gray-700">
                <Link to="/note-summarizer" onClick={closeMobileMenu}>📑 Note Summarizer</Link>
              </li>
              <li className="p-2 hover:bg-gray-700 highlight flex items-center">
                <Link to="/mock-test-generator" onClick={closeMobileMenu}>📝 Mock Test Generator</Link>
                <span className="ml-2 animate-pulse text-yellow-400">⭐</span> {/* Glowing star */}
              </li>
              <li className="p-2 hover:bg-gray-700">
                <Link to="/quillbot" onClick={closeMobileMenu}>🖊️ AI Paraphrasing Tool</Link>
              </li>
              <li className="p-2 hover:bg-gray-700 highlight flex items-center">
                <Link to="/learn-with-podcast" onClick={closeMobileMenu}>🎙️ Learn with Podcast</Link>
                <span className="ml-2 animate-pulse text-yellow-400">⭐</span> {/* Glowing star */}
              </li>
              <li className="p-2 hover:bg-gray-700">
                <Link to="/homework-helper" onClick={closeMobileMenu}>🤖 AI Homework Helper</Link>
              </li>
            </ul>
            
            )}
          </li>
          <li className={`p-2 lg:p-0 underline-animation ${isBlogPageActive?'active_link':''}`}>
            <Link to="/blog" onClick={closeMobileMenu}>Blog</Link>
          </li>
          <li className="relative p-2 lg:p-0" ref={dropdownRef2}>
            <button
              onClick={toggleDropdown2}
              className="flex items-center"
            >
              Documents <FaChevronDown className="ml-2" />
            </button>
            {isDropdownOpen2 && (
              <ul className="lg:absolute left-0 mt-2 lg:w-48 bg-gray-900 text-white shadow-lg rounded-lg z-20">
                <li className="p-2 hover:bg-gray-700">
                  <Link to="/document-queue" onClick={closeMobileMenu}>Queue</Link>
                </li>
                <li className="p-2 hover:bg-gray-700">
                  <Link to="/documents" onClick={closeMobileMenu}>Generated</Link>
                </li>
              </ul>
            )}
          </li>
          <li className={`p-2 lg:p-0 underline-animation ${isReportsPageActive?'active_link':''}`}>
            <Link to="/reports" onClick={closeMobileMenu}>Reports</Link>
          </li>
          <li className={`p-2 lg:p-0 underline-animation ${isSettingsPageActive?'active_link':''}`}>
            <Link to="/settings" onClick={closeMobileMenu}>Settings</Link>
          </li>

          {!user ? (
            <li className="p-2 lg:p-0">
              <button onClick={handleLogin} className="bg-green-500 hover:bg-green-600 text-white font-semibold px-4 py-2 rounded-lg transition duration-300">
                  Login
                </button>
            </li>
          ) : (
            <>
              <li className="p-2 lg:p-0">
                <Link to="/mock-test-importer" onClick={closeMobileMenu}>
                  <button className="bg-green-500 hover:bg-green-600 text-white font-semibold px-4 py-2 rounded-lg transition duration-300 flex items-center">
                    <FaDownload className="mr-2" /> Import Quiz
                  </button>
                </Link>
              </li>
              <li className="p-2 lg:p-0">
                <button onClick={handleLogout} className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-lg transition duration-300">
                  Logout
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
    <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default Navbar;