import React, { useState, useEffect } from "react";
import axios from "axios";
//import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { motion } from 'framer-motion';

const Feedback = ({tool}) => {
  const [feedbackText, setFeedbackText] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmojiSelect = (emoji) => {
    setSelectedEmoji(emoji);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!feedbackText || !selectedEmoji) {
      setErrorMessage("Please provide your feedback and select an emoji.");
      return;
    }

    try {
        console.log(tool.name);
        ReactRecaptcha3.getToken().then(
            async (recaptchaToken) => {
              console.log(recaptchaToken);
              const response = await axios.post("https://app.studentaitools.com/api/send-email", {
                email: 'jignesh78bmw@gmail.com',
                subject: 'New Feedback Submission',
                data: { feedbackText, selectedEmoji, toolName: tool.name },
                type: 'feedback',
                recaptchaToken // Send reCAPTCHA token to server
                });
        
                if (response.status === 200) {
                    setSuccessMessage("Thank you for your feedback!");
                    setFeedbackText("");
                    setSelectedEmoji("");
                }

            },
            (error) => {
              console.log(error);
            }
          );
    } catch (error) {
      setErrorMessage("Failed to submit feedback. Please try again.");
    }
  };

  return (
    <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, ease: 'easeOut' }}
    viewport={{ once: true, amount: 0.1 }}>
      <h2 className="text-2xl font-semibold mb-4">We Value Your Feedback</h2>
      {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
      {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
      <form onSubmit={handleSubmit}>
        <textarea
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          placeholder="What can we improve or add?"
          className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 mb-4"
          rows="4"
          required
        ></textarea>
        <div className="flex items-center mb-4">
          <span className="mr-4">How do you feel about this tool?</span>
          <button
            type="button"
            onClick={() => handleEmojiSelect("😞")}
            className={`px-2 py-1 rounded-full ${selectedEmoji === "😞" ? "bg-green-200" : ""}`}
          >
            😞
          </button>
          <button
            type="button"
            onClick={() => handleEmojiSelect("😐")}
            className={`px-2 py-1 mx-2 rounded-full ${selectedEmoji === "😐" ? "bg-green-200" : ""}`}
          >
            😐
          </button>
          <button
            type="button"
            onClick={() => handleEmojiSelect("😊")}
            className={`px-2 py-1 rounded-full ${selectedEmoji === "😊" ? "bg-green-200" : ""}`}
          >
            😊
          </button>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Send Feedback
        </button>
      </form>
    </motion.div>
  );
};

export default Feedback;